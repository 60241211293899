import {
  DemandItemDetailBlock,
  DemandItemDetailBlockBody,
  DemandItemDetailBlockFooter,
  DemandItemDetailBlockInfo,
} from 'features/demandItem/components/DemandItemDetailBlock';
import { DemandItemPriceTable } from 'features/demandItem/components/DemandItemPriceTable';
import { DEMAND_ITEM_CATEGORY, DEMAND_ITEM_CATEGORY_LABEL } from 'features/demandItem/models';
import {
  DemandItemDetailBlocksFragment$key,
  DemandItemCategory as DemandItemDetailBlocksItemCategory,
} from 'gql/__generated__/DemandItemDetailBlocksFragment.graphql';

import { graphql, useFragment } from 'react-relay';
import { dateFormatWithoutDayOfWeek } from 'utils/date';
import { paths } from 'utils/paths';
import { DemandItemDetailBlocksHeader } from './DemandItemDetailBlocksHeader';

const DemandItemDetailBlocksFragment = graphql`
  fragment DemandItemDetailBlocksFragment on Company {
    demandItems(where: { isActive: true }) {
      edges {
        node {
          id
          name
          category
          supplier {
            name
          }
          demand {
            code
          }
          salesOrderItems {
            salesOrderDetail {
              orderDetails(first: 1, orderBy: { field: CREATED_AT, direction: DESC }) {
                edges {
                  node {
                    order {
                      createdAt
                    }
                  }
                }
              }
            }
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
          ...DemandItemDetailBlocksHeaderFragment
        }
      }
    }
  }
`;

type Props = {
  queryRef: DemandItemDetailBlocksFragment$key;
};

// TODO: keyにDemandItemDetailBlocksItemCategory型を使用したオブジェクトにリファクタ予定
const getDemandItemCategory = (category: DemandItemDetailBlocksItemCategory) => {
  if (category === DEMAND_ITEM_CATEGORY.Cardboard) return DEMAND_ITEM_CATEGORY.Cardboard;
  if (category === DEMAND_ITEM_CATEGORY.FlexiblePackage)
    return DEMAND_ITEM_CATEGORY.FlexiblePackage;
  if (category === DEMAND_ITEM_CATEGORY.GiftBox) return DEMAND_ITEM_CATEGORY.GiftBox;
  if (category === DEMAND_ITEM_CATEGORY.PaperBag) return DEMAND_ITEM_CATEGORY.PaperBag;
  return DEMAND_ITEM_CATEGORY.Other;
};

export const DemandItemDetailBlocks = ({ queryRef }: Props) => {
  const { demandItems } = useFragment(DemandItemDetailBlocksFragment, queryRef);

  if (
    !demandItems ||
    !demandItems.edges ||
    demandItems.edges.length === 0 ||
    !demandItems.edges[0]?.node ||
    demandItems.edges[0]?.node === null
  )
    return null;

  const items = demandItems.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  return (
    <>
      {items.map((item) => {
        return (
          <DemandItemDetailBlock key={item.id}>
            <DemandItemDetailBlocksHeader queryRef={item} />
            <DemandItemDetailBlockBody>
              <DemandItemDetailBlockInfo
                values={[
                  {
                    label: 'コード',
                    node: item.demand?.code || '-',
                  },
                  {
                    label: 'サプライヤー',
                    node: item.supplier?.name || '-',
                  },
                  {
                    label: 'カテゴリー',
                    node:
                      DEMAND_ITEM_CATEGORY_LABEL[getDemandItemCategory(item.category)]
                        .categoryName || '-',
                  },
                  {
                    label: '最終発注日',
                    node: dateFormatWithoutDayOfWeek(
                      item.salesOrderItems?.[0]?.salesOrderDetail?.orderDetails?.edges?.[0]?.node
                        ?.order?.createdAt,
                    ),
                  },
                ]}
              />

              <DemandItemPriceTable
                values={(item.prices || []).map((price) => ({
                  id: price.id,
                  quantity: price.quantity,
                  unitPrice: price.unitPrice,
                  unitSellingPrice: price.unitSellingPrice,
                }))}
              />
            </DemandItemDetailBlockBody>
            <DemandItemDetailBlockFooter
              editPath={paths.demandItems._id(item.id).edit.url()}
              detailPath={paths.demandItems._id(item.id).url()}
              onDelete={() => {
                console.log('delete');
              }}
              disabledDelete={(item.salesOrderItems || []).length > 0}
            />
          </DemandItemDetailBlock>
        );
      })}
    </>
  );
};
