import { extendTheme } from '@chakra-ui/react';
import { Alert } from 'theme/components/Alert';
import { Heading } from 'theme/components/Heading';
import { Table } from 'theme/components/Table';
import styles from 'theme/foundations/styles';

const overrides = {
  styles,
  components: {
    Alert,
    Table,
    Heading,
  },
};

export default extendTheme(overrides);
