import { Loading } from 'components/Loading';
import { OrderDownload } from 'features/order/components/OrderDownload';
import { OrderForm } from 'features/order/components/OrderForm';
import { OrderSendingForm } from 'features/order/components/OrderSendingForm';
import { useOrderForm } from 'features/order/hooks/useOrderForm';
import { convertSalesOrderToFormStateFormat } from 'features/order/util';
import { ZodOrderFormData } from 'features/order/zod';
import { new_OrderNewPageFragment$key } from 'gql/__generated__/new_OrderNewPageFragment.graphql';
import { new_OrderNewPageQuery } from 'gql/__generated__/new_OrderNewPageQuery.graphql';
import { useOwnCompanyInfo } from 'hooks/useOwnCompanyInfo';
import { Suspense, useEffect } from 'react';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import { Navigate, useSearchParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { concatFullName } from 'utils/label';
import { paths } from 'utils/paths';

const orderNewPageQuery = graphql`
  query new_OrderNewPageQuery ($salesOrderId: ID!, $isSkipToLoadSalesOrder: Boolean!){
    ...new_OrderNewPageFragment @arguments(salesOrderId: $salesOrderId, isSkipToLoadSalesOrder: $isSkipToLoadSalesOrder)
    ...OrderFormFragment
  }
`;

const orderNewPageFragment = graphql`
  fragment new_OrderNewPageFragment on Query 
    @argumentDefinitions(
      salesOrderId: {type: "ID"}
      isSkipToLoadSalesOrder: {type: "Boolean", defaultValue: true}
    )
  {
    salesOrders(where: {id: $salesOrderId}) @skip(if: $isSkipToLoadSalesOrder) {
      edges {
        node {
          id
          demand {
            id
            name
          } 
          
          supplier {
            id
            name
          }
          
          details {
            edges {
              node {
                id
                item {
                  id
                  name
                  quantity
                  unitPrice
                  taxCategory {
                    id
                    rate
                  }
                }
              }
            }
          }
          internalAssignees {
            user {
              id
              profile {
                lastName
                firstName
              }
            }
          }
        }
      }
    }
    
    currentUser {
      userID
      firstName
      lastName
      phoneNumber
    }
      
    taxCategories {
      edges {
        node {
          id
          name
          rate
        }
      }
    }
  }
`;

export const OrderNewPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const salesOrderId = searchParams.get('salesOrderId');
  const type = searchParams.get('type');
  const query = useLazyLoadQuery<new_OrderNewPageQuery>(
    orderNewPageQuery,
    {
      salesOrderId: salesOrderId || '',
      isSkipToLoadSalesOrder: !salesOrderId,
    },
    { fetchPolicy: 'network-only' },
  );
  const companyInfo = useOwnCompanyInfo();
  const { salesOrders, currentUser } = useFragment(
    orderNewPageFragment,
    query as new_OrderNewPageFragment$key,
  );

  const salesOrder = salesOrders?.edges?.[0]?.node;
  let state: ZodOrderFormData | null = null;

  if (salesOrder) {
    state = convertSalesOrderToFormStateFormat({
      salesOrder: salesOrder,
      companyInfo,
      currentUser,
    });
  }

  const {
    data,
    showPreview,
    onClickToConfirm,
    onSubmit,
    isMutationInFlight,
    onClickToNewForm,
    updateSubmitType,
    sendingData,
  } = useOrderForm({
    salesOrderId: salesOrderId || '',
    state: {
      title: state?.title || '',
      company: {
        id: state?.company.id || '',
        name: state?.company.name || '',
      },
      supplier: {
        id: state?.supplier.id || '',
        name: state?.supplier.name || '',
      },
      detailMessage: state?.detailMessage || '',
      deliveryInfo: state?.deliveryInfo || {},
      details: state?.details || [],
      internalAssignees: state?.internalAssignees || [
        {
          value: currentUser.userID,
          label: concatFullName({
            lastName: currentUser.lastName,
            firstName: currentUser.firstName,
          }),
        },
      ],
    },
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (!showPreview) {
      searchParams.delete('type');
      setSearchParams(searchParams);
    }
  }, [showPreview]);

  if (!salesOrderId) return <Navigate replace to={paths.order.url()} />;

  return (
    <Suspense fallback={<Loading />}>
      {type && showPreview ? (
        <>
          {type === 'sending' && (
            <OrderSendingForm
              onClickSubmit={onSubmit}
              values={data}
              isMutationInFlight={isMutationInFlight}
              onClickToNewForm={onClickToNewForm}
              sendingData={sendingData}
            />
          )}
          {type === 'download' && (
            <OrderDownload
              onClickSubmit={onSubmit}
              values={data}
              isMutationInFlight={isMutationInFlight}
              onClickToNewForm={onClickToNewForm}
            />
          )}
        </>
      ) : (
        <OrderForm
          onClickToConfirm={onClickToConfirm}
          data={data}
          queryRef={query}
          updateSubmitType={updateSubmitType}
          previousUrl={salesOrder ? paths.salesOrder.id(salesOrder.id).url() : undefined}
        />
      )}
    </Suspense>
  );
};
