import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

type Props = {
  itemName: string;
  value: string;
};

export const DemandRecurringCostBlockHeader = ({ itemName, value }: Props) => (
  <Table w="100%">
    <Thead>
      <Tr>
        <Th bg="gray.50">費用名</Th>
        <Th bg="gray.50">補足</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr fontSize="sm">
        <Td>{itemName}</Td>
        <Td>{value}</Td>
      </Tr>
    </Tbody>
  </Table>
);
