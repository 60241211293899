import { DemandItemCategory as DemandItemCategoryEnum } from 'gql/graphql.types';

export type DemandItemCategory = DemandItemCategoryEnum;

export const DEMAND_ITEM_CATEGORY = DemandItemCategoryEnum;

export const DEMAND_ITEM_CATEGORY_LABEL = {
  [DEMAND_ITEM_CATEGORY.Cardboard]: {
    categoryName: 'ダンボール',
    tableLabels: ['サイズ', '形状', '構成', '段厚', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.FlexiblePackage]: {
    categoryName: '軟包材',
    tableLabels: ['サイズ', '形状', '構成', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.GiftBox]: {
    categoryName: '化粧箱',
    tableLabels: ['サイズ', '形状', '用紙', '印刷', '加工', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.PaperBag]: {
    categoryName: '紙袋',
    tableLabels: ['サイズ', '用紙', '印刷', '加工', '持ち手', 'その他'],
  },
  [DEMAND_ITEM_CATEGORY.Other]: {
    categoryName: 'その他',
    tableLabels: ['その他'],
  },
} as const;
