/**
 * @generated SignedSource<<af9e55e21ca1797a9a4c27d4198baa9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DemandItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandItemDetailBlocksFragment$data = {
  readonly demandItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly category: DemandItemCategory;
        readonly demand: {
          readonly code: string;
        };
        readonly id: string;
        readonly name: string;
        readonly prices: ReadonlyArray<{
          readonly id: string;
          readonly quantity: any;
          readonly unitPrice: number;
          readonly unitSellingPrice: number;
        }> | null;
        readonly salesOrderItems: ReadonlyArray<{
          readonly salesOrderDetail: {
            readonly orderDetails: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly order: {
                    readonly createdAt: any;
                  };
                } | null;
              } | null> | null;
            };
          };
        }> | null;
        readonly supplier: {
          readonly name: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlocksHeaderFragment">;
      } | null;
    } | null> | null;
  };
  readonly " $fragmentType": "DemandItemDetailBlocksFragment";
};
export type DemandItemDetailBlocksFragment$key = {
  readonly " $data"?: DemandItemDetailBlocksFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlocksFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemDetailBlocksFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "isActive": true
          }
        }
      ],
      "concreteType": "DemandItemConnection",
      "kind": "LinkedField",
      "name": "demandItems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DemandItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DemandItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "category",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Supplier",
                  "kind": "LinkedField",
                  "name": "supplier",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Company",
                  "kind": "LinkedField",
                  "name": "demand",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SalesOrderItem",
                  "kind": "LinkedField",
                  "name": "salesOrderItems",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SalesOrderDetail",
                      "kind": "LinkedField",
                      "name": "salesOrderDetail",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Literal",
                              "name": "first",
                              "value": 1
                            },
                            {
                              "kind": "Literal",
                              "name": "orderBy",
                              "value": {
                                "direction": "DESC",
                                "field": "CREATED_AT"
                              }
                            }
                          ],
                          "concreteType": "OrderDetailConnection",
                          "kind": "LinkedField",
                          "name": "orderDetails",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "OrderDetailEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "OrderDetail",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Order",
                                      "kind": "LinkedField",
                                      "name": "order",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "createdAt",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": "orderDetails(first:1,orderBy:{\"direction\":\"DESC\",\"field\":\"CREATED_AT\"})"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DemandItemPrice",
                  "kind": "LinkedField",
                  "name": "prices",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitSellingPrice",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DemandItemDetailBlocksHeaderFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "demandItems(where:{\"isActive\":true})"
    }
  ],
  "type": "Company",
  "abstractKey": null
};
})();

(node as any).hash = "8ea89bba721ee9b58a28371c6f8b25ac";

export default node;
