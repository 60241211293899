import { Button, HStack } from '@chakra-ui/react';
import { ArrowRight, Delete, Edit } from '@icon-park/react';
import { Link as RouterLink } from 'react-router-dom';
type Props = {
  onDelete?: () => void;
  disabledDelete?: boolean;
  editPath?: string;
  detailPath?: string;
};

export const DemandRecurringCostBlockFooter = ({
  editPath,
  detailPath,
  disabledDelete = false,
  onDelete,
}: Props) => {
  const handleDelete = () => {
    if (!onDelete) return;
    onDelete();
  };

  return (
    <HStack spacing={4} alignItems="flex-start" justify="space-between" w="100%" px={4}>
      {onDelete && (
        <Button leftIcon={<Delete />} onClick={handleDelete} disabled={disabledDelete}>
          削除
        </Button>
      )}

      <HStack spacing={4}>
        {editPath && (
          <Button colorScheme="blue" leftIcon={<Edit />} as={RouterLink} to={editPath}>
            編集
          </Button>
        )}
        {detailPath && (
          <Button colorScheme="blue" rightIcon={<ArrowRight />} as={RouterLink} to={detailPath}>
            詳細
          </Button>
        )}
      </HStack>
    </HStack>
  );
};
