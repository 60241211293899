import { Checkbox, HStack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';

type Props = {
  values: {
    id: string;
    quantity: number;
    unitPrice: number;
    unitSellingPrice: number;
  }[];
  enableCheckbox?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DemandRecurringCostTable = ({ onChange, values, enableCheckbox = false }: Props) => {
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange(e);
  };

  return (
    <HStack spacing={4} alignItems="stretch">
      <Text
        border="1px solid"
        borderColor="gray.200"
        lineHeight={1.2}
        p={2}
        fontSize="sm"
        fontWeight="bold"
        textAlign="center"
        sx={{ writingMode: 'vertical-rl' }}
      >
        単価表
      </Text>
      <Table w="100%" minW="366px">
        <Thead>
          <Tr>
            {enableCheckbox && (
              <Th p={2} w="24px" whiteSpace="nowrap">
                登録
              </Th>
            )}
            <Th p={2} isNumeric>
              数量
            </Th>
            <Th p={2} isNumeric>
              仕入単価
            </Th>
            <Th p={2} isNumeric>
              顧客単価
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {values.map((value) => (
            <Tr
              key={value.id}
              sx={{
                ':last-of-type td': {
                  border: 'none',
                },
              }}
            >
              {enableCheckbox && (
                <Td w="24px" textAlign="center" p={2}>
                  <Checkbox onChange={handleChangeValue} />
                </Td>
              )}
              <Td isNumeric fontSize="xs" p={2}>
                {value.quantity.toLocaleString()}
              </Td>
              <Td isNumeric fontSize="xs" p={2}>
                {value.unitPrice.toLocaleString()}
              </Td>
              <Td isNumeric fontSize="xs" p={2}>
                {value.unitSellingPrice.toLocaleString()}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </HStack>
  );
};
