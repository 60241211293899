import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CustomFormLabel } from 'components/CustomFormLabel';
import { ErrorMessage } from 'components/ErrorMessage';
import { PageBack } from 'components/PageBack';
import { SubHeading } from 'components/SubHeading';
import { CompanyComboBox } from 'features/company/components/CompanyComboBox';
import { DemandMemo } from 'features/company/components/DemandMemo';
import {
  SALES_ORDER_FORM_TYPE,
  SalesOrderFormType,
  SalesOrderFormValuesType,
  salesOrderFormSchema,
} from 'features/salesOrders/form';
import { useQueryParams } from 'features/salesOrders/hooks/useSalesOrderQueryPrams';
import { SupplierComboBox } from 'features/supplier/components/SupplierComboBox';
import { UserMultipleSelectBox } from 'features/user/components/UserMultipleSelectBox';
import { SalesOrderFormQuery as SalesOrderFormQueryType } from 'gql/__generated__/SalesOrderFormQuery.graphql';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLazyLoadQuery } from 'react-relay';
import { Link } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';
import { SalesOrderFormDetailPrices } from './SalesOrderFormDetailPrices';
import { SalesOrderFormDetails } from './SalesOrderFormDetails';

type Props = {
  type: SalesOrderFormType;
  defaultValues?: SalesOrderFormValuesType;
  onClickToConfirm: (data: SalesOrderFormValuesType, isDirty: boolean) => void;
  previousUrl?: string;
  hasRelatedOrder?: boolean;
  hasRelatedInvoice?: boolean;
  isDirtyMemo: boolean;
  setShowBlocker: (value: boolean) => void;
};

const getPageLabel = (type: SalesOrderFormType) => {
  switch (type) {
    case SALES_ORDER_FORM_TYPE.new:
      return '発注請書の作成';
    case SALES_ORDER_FORM_TYPE.edit:
      return '発注請書の編集';
  }
};

const salesOrderFormQuery = graphql`
  query SalesOrderFormQuery   {
    ...SalesOrderFormDetailsFragment
  }
`;

export const SalesOrderForm = ({
  defaultValues,
  onClickToConfirm,
  type,
  previousUrl,
  hasRelatedInvoice = false,
  hasRelatedOrder = false,
  setShowBlocker,
  isDirtyMemo,
}: Props) => {
  const { queryParams } = useQueryParams();
  const query = useLazyLoadQuery<SalesOrderFormQueryType>(
    salesOrderFormQuery,
    {},
    { fetchPolicy: 'network-only' },
  );

  const {
    register,
    control,
    formState: { errors, isSubmitting, isDirty, isValid },
    handleSubmit,
    setValue,
    getValues,
    watch,
  } = useForm<SalesOrderFormValuesType>({
    resolver: zodResolver(salesOrderFormSchema),
    defaultValues: defaultValues,
  });

  const demandId = watch('company').id;

  const onSubmit = (data: SalesOrderFormValuesType) => onClickToConfirm(data, isDirty);

  const onClearCompanyInput = () => {
    setValue('company', { id: '', name: '' });
  };

  const onClearSupplierInput = () => setValue('supplier', { id: '', name: '' });

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    if (isDirtyMemo || isDirty) {
      setShowBlocker(true);
    } else {
      setShowBlocker(false);
    }
  }, [isDirty, isDirtyMemo]);

  return (
    <>
      <Box mb={6}>
        <Link
          to={
            previousUrl ??
            paths.salesOrder.url({
              assignee: queryParams.assignee,
              demand: queryParams.demand,
              transactionId: queryParams.transactionId,
              supplier: queryParams.supplier,
            })
          }
        >
          <PageBack />
        </Link>
      </Box>
      <Box mb={6}>
        <SubHeading label={getPageLabel(type)} />
      </Box>
      <Box w="992px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6} alignItems="flex-start">
            <HStack gap={4} align="start" w="4xl">
              <FormControl
                isInvalid={!!errors.company}
                isRequired
                isDisabled={hasRelatedOrder || hasRelatedInvoice}
              >
                <VStack gap={2} align="stretch">
                  <Box>
                    <FormLabel>デマンド</FormLabel>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <CompanyComboBox
                          onChangeSelected={(value) => {
                            onChange(value);
                          }}
                          defaultSelectedItem={getValues('company')}
                          onClearInput={onClearCompanyInput}
                          isDisabled={hasRelatedOrder || hasRelatedInvoice}
                        />
                      )}
                    />
                    <ErrorMessage name="company" errors={errors} />
                  </Box>
                  <DemandMemo demandId={demandId} />
                </VStack>
              </FormControl>
              <FormControl isInvalid={!!errors.supplier} isRequired isDisabled={hasRelatedOrder}>
                <FormLabel>サプライヤー</FormLabel>
                <Controller
                  name="supplier"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SupplierComboBox
                      onChangeSelected={onChange}
                      defaultSelectedItem={getValues('supplier')}
                      onClearInput={onClearSupplierInput}
                      isDisabled={hasRelatedOrder}
                    />
                  )}
                />
                <ErrorMessage name="supplier" errors={errors} />
              </FormControl>
            </HStack>

            <Box w="4xl">
              <HStack spacing={5} align="top" w="4xl">
                <FormControl isInvalid={!!errors.title} isRequired>
                  <FormLabel>件名</FormLabel>
                  <Input type="string" {...register('title')} />
                  <ErrorMessage name="title" errors={errors} />
                </FormControl>
                <FormControl isInvalid={!!errors.receivedDate} w="10rem" isRequired>
                  <FormLabel>受注日</FormLabel>
                  <Input type="date" {...register('receivedDate')} />
                  <ErrorMessage name="receivedDate" errors={errors} />
                </FormControl>
              </HStack>
            </Box>

            <VStack align="start">
              <VStack align="stretch" spacing={2}>
                <HStack align="flex-start" spacing={2}>
                  <Box w={4} />
                  <CustomFormLabel isRequired w="22.5rem">
                    品名
                  </CustomFormLabel>
                  <CustomFormLabel w="10rem">確定納品日</CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    数量
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    仕入単価
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7.5rem">
                    顧客単価
                  </CustomFormLabel>
                  <CustomFormLabel isRequired w="7rem">
                    税区分
                  </CustomFormLabel>
                  <CustomFormLabel w="7.5rem">金額 (税抜)</CustomFormLabel>
                </HStack>
                <SalesOrderFormDetails
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  errors={errors}
                  queryRef={query}
                />
              </VStack>
            </VStack>

            <HStack spacing={6} align="flex-start" justifyContent="space-between" w="4xl">
              <VStack w="70%">
                <FormControl isInvalid={!!errors.detailMessage}>
                  <FormLabel>備考</FormLabel>
                  <Textarea {...register('detailMessage')} rows={8} />
                  <ErrorMessage name="detailMessage" errors={errors} />
                </FormControl>
                <FormControl isInvalid={!!errors.memo}>
                  <FormLabel>社内メモ</FormLabel>
                  <Textarea {...register('memo')} rows={8} />
                  <ErrorMessage name="memo" errors={errors} />
                  <Text color="gray.500" fontSize="sm" mt={1}>
                    初期費用の減額や値引きを行った場合は必ず理由を記載してください
                  </Text>
                </FormControl>
                <FormControl isInvalid={!!errors.internalAssignees} isRequired>
                  <FormLabel>担当者</FormLabel>
                  <Controller
                    name="internalAssignees"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <UserMultipleSelectBox
                        onChange={onChange}
                        defaultValue={getValues('internalAssignees')}
                        menuPlacement="top"
                      />
                    )}
                  />
                  <ErrorMessage name="internalAssignees" errors={errors} />
                </FormControl>
              </VStack>

              <SalesOrderFormDetailPrices control={control} />
            </HStack>
            <Box w="4xl">
              <Button
                colorScheme="blue"
                w="full"
                type="submit"
                isLoading={isSubmitting}
                onClick={() => {
                  if (isValid) {
                    setShowBlocker(false);
                  }
                }}
              >
                確認画面へ進む
              </Button>
            </Box>
          </VStack>
        </form>
      </Box>
    </>
  );
};
