import { Box, Button, Checkbox, Flex, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import { AddOne, Edit } from '@icon-park/react';
import { PageBack } from 'components/PageBack';
import { PageHeading } from 'components/PageHeading';
import { SubHeading } from 'components/SubHeading';
import { detail_DemandDetailPageQuery } from 'gql/__generated__/detail_DemandDetailPageQuery.graphql';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { dateFormatWithTime } from 'utils/date';
import { MissingRequiredParamError } from 'utils/error';
import { paths } from 'utils/paths';
import { DemandItemDetailBlocks } from './components/DemandItemDetailBlocks';
import { DemandRecurringCostBlocks } from './components/DemandRecurringCostBlocks';

const DemandDetailPageQuery = graphql`
  query detail_DemandDetailPageQuery  ($id: ID!) {
    companies(where: {id: $id}) {
      edges {
        node {
          id
          name
          code
          createdAt
          internalMemos {
            body
          }
          ...DemandItemDetailBlocksFragment
          ...DemandRecurringCostBlocksFragment
        }
      }
    }
  }
`;

export function DemandsDetailPage() {
  const { id } = useParams();
  if (!id) {
    throw new MissingRequiredParamError('id');
  }

  const { companies } = useLazyLoadQuery<detail_DemandDetailPageQuery>(
    DemandDetailPageQuery,
    {
      id,
    },
    { fetchPolicy: 'network-only' },
  );

  if (
    !companies ||
    !companies.edges ||
    companies.edges.length === 0 ||
    !companies.edges[0]?.node ||
    companies.edges[0]?.node === null
  )
    return null;

  const demand = companies.edges[0].node;
  const memo = demand.internalMemos?.[0]?.body || '-';

  return (
    <VStack spacing={8} alignItems="flex-start">
      <PageHeading label={`デマンド: ${demand.name}`} />
      <Flex justify="space-between" width="768px">
        <RouterLink to={paths.demands.url()}>
          <PageBack />
        </RouterLink>
      </Flex>
      <SubHeading label="デマンドの詳細" />

      <VStack spacing={10} alignItems="flex-start" minW="1160px">
        <VStack spacing={4} alignItems="flex-start">
          <HStack spacing={4}>
            <VStack spacing={1} alignItems="flex-start">
              <Box color="gray.500">デマンド名</Box>
              <Box>{demand.name}</Box>
            </VStack>
            <VStack spacing={1} alignItems="flex-start">
              <Box color="gray.500">コード</Box>
              <Box>{demand.code}</Box>
            </VStack>
            <VStack spacing={1} alignItems="flex-start">
              <Box color="gray.500">作成日時</Box>
              <Box>{dateFormatWithTime(demand.createdAt)}</Box>
            </VStack>
          </HStack>
          <VStack spacing={1} alignItems="flex-start">
            <Box color="gray.500">メモ</Box>
            <Box whiteSpace="pre-wrap">{memo}</Box>
          </VStack>
          <HStack spacing={4}>
            <Button colorScheme="blue" leftIcon={<Edit />}>
              編集
            </Button>
            <Button colorScheme="blue">リピート発注</Button>
          </HStack>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" w="100%">
          <Heading as="h3" fontSize="xl">
            商品
          </Heading>
          <Button colorScheme="blue" leftIcon={<AddOne />} width="15rem">
            新規商品追加
          </Button>
          <HStack
            width="100%"
            border="1px solid"
            borderColor="gray.200"
            borderRadius={8}
            height="1024px"
            overflow="hidden"
            alignItems="flex-start"
            spacing={0}
          >
            <VStack
              w="200px"
              alignItems="flex-start"
              p={4}
              flexShrink={0}
              borderRight="1px solid"
              borderColor="gray.200"
              h="100%"
            >
              <Heading as="h4" fontSize="md">
                フィルター
              </Heading>
              <VStack spacing={2} alignItems="flex-start">
                <VStack spacing={1} alignItems="flex-start">
                  <Text fontSize="sm" color="gray.500">
                    カテゴリー
                  </Text>
                  <VStack spacing={1} alignItems="flex-start">
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                  </VStack>
                </VStack>
                <VStack spacing={1} alignItems="flex-start">
                  <Text fontSize="sm" color="gray.500">
                    サプライヤー
                  </Text>
                  <VStack spacing={1} alignItems="flex-start">
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                    <Checkbox fontSize="sm">ダンボール (100)</Checkbox>
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
            <Box w="100%" h="100%" overflow="scroll" overscrollBehavior="auto">
              <DemandItemDetailBlocks queryRef={demand} />
            </Box>
          </HStack>
        </VStack>

        <VStack spacing={4} alignItems="flex-start" w="100%">
          <Heading as="h3" fontSize="xl">
            継続費用
          </Heading>
          <Button colorScheme="blue" leftIcon={<AddOne />} width="15rem">
            新規継続費用追加
          </Button>
          <HStack
            width="100%"
            border="1px solid"
            borderColor="gray.200"
            borderRadius={8}
            overflow="hidden"
            alignItems="flex-start"
            spacing={0}
          >
            <Box w="100%" h="100%" overflow="scroll">
              <DemandRecurringCostBlocks queryRef={demand} />
            </Box>
          </HStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
