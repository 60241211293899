import { Button, Td, Tr } from '@chakra-ui/react';
import { DemandsTableRowFragment$key } from 'gql/__generated__/DemandsTableRowFragment.graphql';
import { useFragment } from 'react-relay';
import { Link as RouterLink } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import { paths } from 'utils/paths';

const DemandsTableRowFragment = graphql`
  fragment DemandsTableRowFragment on Company {
    id
    code
    name
  }
`;

export const DemandsTableRow = ({ queryRef }: { queryRef: DemandsTableRowFragment$key }) => {
  const { name, code, id } = useFragment(DemandsTableRowFragment, queryRef);

  return (
    <Tr>
      <Td>{code}</Td>
      <Td>{name || '-'} </Td>
      <Td w="40px">
        <Button as={RouterLink} colorScheme="blue" size="sm" to={paths.demands._id(id).url()}>
          詳細
        </Button>
      </Td>
    </Tr>
  );
};
