/**
 * @generated SignedSource<<8504b1cef6dcd1c743d1b3b5963eb1f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DemandItemCategory = "cardboard" | "flexiblePackage" | "giftBox" | "other" | "paperBag" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DemandItemDetailBlocksHeaderFragment$data = {
  readonly cardboard: {
    readonly __typename: "DemandItemCardboard";
    readonly material: string | null;
    readonly other: string | null;
    readonly printingColor: string | null;
    readonly processings: string | null;
    readonly size: string | null;
    readonly thickness: string | null;
    readonly type: string | null;
  } | null;
  readonly category: DemandItemCategory;
  readonly flexiblePackage: {
    readonly __typename: "DemandItemFlexiblePackage";
    readonly material: string | null;
    readonly other: string | null;
    readonly printingColor: string | null;
    readonly processings: string | null;
    readonly size: string | null;
    readonly type: string | null;
  } | null;
  readonly giftBox: {
    readonly __typename: "DemandItemGiftBox";
    readonly other: string | null;
    readonly paperType: string | null;
    readonly printingColor: string | null;
    readonly processings: string | null;
    readonly size: string | null;
    readonly type: string | null;
  } | null;
  readonly name: string;
  readonly other: {
    readonly __typename: "DemandItemOther";
    readonly specText: string | null;
  } | null;
  readonly paperBag: {
    readonly __typename: "DemandItemPaperBag";
    readonly handle: string | null;
    readonly other: string | null;
    readonly paperType: string | null;
    readonly printingColor: string | null;
    readonly processings: string | null;
    readonly size: string | null;
  } | null;
  readonly " $fragmentType": "DemandItemDetailBlocksHeaderFragment";
};
export type DemandItemDetailBlocksHeaderFragment$key = {
  readonly " $data"?: DemandItemDetailBlocksHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DemandItemDetailBlocksHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "material",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printingColor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processings",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "other",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paperType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DemandItemDetailBlocksHeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemCardboard",
      "kind": "LinkedField",
      "name": "cardboard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thickness",
          "storageKey": null
        },
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemFlexiblePackage",
      "kind": "LinkedField",
      "name": "flexiblePackage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemGiftBox",
      "kind": "LinkedField",
      "name": "giftBox",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v7/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemPaperBag",
      "kind": "LinkedField",
      "name": "paperBag",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v7/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "handle",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DemandItemOther",
      "kind": "LinkedField",
      "name": "other",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specText",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DemandItem",
  "abstractKey": null
};
})();

(node as any).hash = "9f60831863caed772a54adb198c27223";

export default node;
