import { generatePath } from 'react-router-dom';

const buildQueryParameter = (params?: { [key: string]: string | undefined }): string => {
  if (!params) return '';

  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      searchParams.append(key, value);
    }
  });

  const queryString = searchParams.toString();
  return queryString;
};
export const paths = {
  root: () => '/',
  estimationRequest: {
    _id: (id: string) => generatePath('/estimation-request/:id', { id }),
    url: (params?: {
      assignee?: string;
      demand?: string;
      supplier?: string;
      searchWord?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/estimation-request?${queryParams}`;
      return '/estimation-request';
    },
    new: {
      url: (type?: 'draft') =>
        type === 'draft' ? '/estimation-request/new?type=draft' : '/estimation-request/new',
    },
    duplication: {
      _id: (id: string) => generatePath('/estimation-request/duplication/:id', { id }),
    },
    addAssignees: {
      _id: (id: string) => generatePath('/estimation-request/add-assignees/:id', { id }),
    },
  },
  estimationResponse: {
    url: (token: string, isInternal = true) => {
      return `/estimation-response?token=${token}&isInternal=${isInternal}`;
    },
  },
  estimation: {
    _id: (id: string) => ({
      url: generatePath('/estimation/:id', { id }),
    }),
    url: (params?: { assignee?: string; demand?: string; supplier?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/estimation?${queryParams}`;
      return '/estimation';
    },
    new: {
      url: (
        params: { responseId?: string; requestAssigneeId?: string } | undefined = undefined,
      ) => {
        const queryParams = buildQueryParameter(params);
        if (queryParams) return `/estimation/new?${queryParams}`;
        return '/estimation/new';
      },
    },
    duplication: {
      _id: (id: string) => generatePath('/estimation/duplication/:id', { id }),
    },
    edit: {
      _id: (id: string) => generatePath('/estimation/edit/:id', { id }),
    },
  },
  order: {
    _id: (id: string) => generatePath('/order/:id', { id }),
    url: (params?: { assignee?: string; demand?: string; supplier?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/order?${queryParams}`;
      return '/order';
    },
    new: {
      url: (estimationId?: string) =>
        estimationId ? `/order/new?estimationId=${estimationId}` : '/order/new',
      urlWithSalesOrderId: (salesOrderId: string) => `/order/new?salesOrderId=${salesOrderId}`,
    },
    duplication: {
      _id: (id: string) => generatePath('/order/duplication/:id', { id }),
    },
    edit: {
      _id: (id: string) => generatePath('/order/edit/:id', { id }),
    },
    sending: {
      _id: (id: string) => generatePath('/order/sending/:id', { id }),
    },
  },
  salesOrder: {
    id: (id: string) => ({
      url: () => generatePath('/sales-orders/:id', { id }),
      edit: {
        url: () => generatePath('/sales-orders/:id/edit', { id }),
      },
    }),
    url: (params?: {
      assignee?: string;
      demand?: string;
      transactionId?: string;
      supplier?: string;
    }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/sales-orders?${queryParams}`;
      return '/sales-orders';
    },
    new: {
      url: (estimationId?: string) => {
        const base = '/sales-orders/new';
        return estimationId ? `${base}?estimationId=${estimationId}` : base;
      },
    },
  },
  invoice: {
    _id: (id: string) => generatePath('/invoice/:id', { id }),
    url: (params?: { creator?: string; demand?: string }) => {
      const queryParams = buildQueryParameter(params);
      if (queryParams) return `/invoice?${queryParams}`;
      return '/invoice';
    },
    new: {
      url: () => '/invoice/new',
    },
    edit: {
      _id: (id: string) => generatePath('/invoice/edit/:id', { id }),
    },
  },
  company: {
    _id: (id: string) => ({
      url: generatePath('/company/:id', { id }),
    }),
    new: {
      url: () => '/company/new',
    },
    edit: {
      _id: (id: string) => generatePath('/company/:id/edit', { id }),
    },
    url: () => '/company',
  },
  supplier: {
    _id: (id: string) => ({
      url: () => generatePath('/supplier/:id', { id }),
    }),
    new: {
      url: () => '/supplier/new',
    },
    edit: {
      _id: (id: string) => generatePath('/supplier/edit/:id', { id }),
    },
    url: () => '/supplier',
  },
  demands: {
    _id: (id: string) => ({
      url: () => generatePath('/demands/:id', { id }),
    }),
    url: () => '/demands',
  },
  demandItems: {
    _id: (id: string) => ({
      url: () => generatePath('/demand-items/:id', { id }),
      edit: {
        url: () => generatePath('/demand-items/:id/edit', { id }),
      },
    }),
  },
  demandRecurringCosts: {
    _id: (id: string) => ({
      edit: {
        url: () => generatePath('/demand-recurring-costs/:id/edit', { id }),
      },
    }),
  },
  settings: {
    profile: {
      url: () => '/settings/profile',
    },
  },
  login: {
    url: () => '/login',
  },
};
