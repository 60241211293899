import {
  DemandRecurringCostBlock,
  DemandRecurringCostBlockBody,
  DemandRecurringCostBlockFooter,
  DemandRecurringCostBlockHeader,
  DemandRecurringCostBlockInfo,
} from 'features/demandRecurringCost/components/DemandRecurringCostBlock';
import { DemandRecurringCostTable } from 'features/demandRecurringCost/components/DemandRecurringCostTable';
import { DemandRecurringCostBlocksFragment$key } from 'gql/__generated__/DemandRecurringCostBlocksFragment.graphql';

import { graphql, useFragment } from 'react-relay';
import { dateFormatWithoutDayOfWeek } from 'utils/date';
import { paths } from 'utils/paths';

const DemandRecurringCostBlocksFragment = graphql`
  fragment DemandRecurringCostBlocksFragment on Company {
    demandRecurringCosts(where: { isActive: true }) {
      edges {
        node {
          id
          name
          note
          supplier {
            name
          }
          orderRecurringCosts {
            updatedAt
          }
          internalMemos {
            body
          }
          prices {
            id
            quantity
            unitPrice
            unitSellingPrice
          }
        }
      }
    }
  }
`;

type Props = {
  queryRef: DemandRecurringCostBlocksFragment$key;
};

export const DemandRecurringCostBlocks = ({ queryRef }: Props) => {
  const { demandRecurringCosts } = useFragment(DemandRecurringCostBlocksFragment, queryRef);

  if (
    !demandRecurringCosts ||
    !demandRecurringCosts.edges ||
    demandRecurringCosts.edges.length === 0 ||
    !demandRecurringCosts.edges[0]?.node ||
    demandRecurringCosts.edges[0]?.node === null
  )
    return null;

  const costs = demandRecurringCosts.edges
    .map((edge) => {
      if (!edge?.node) return null;
      return edge.node;
    })
    .filter((value) => value != null);

  return (
    <>
      {costs.map((cost) => {
        return (
          <DemandRecurringCostBlock key={cost.id}>
            <DemandRecurringCostBlockHeader itemName={cost.name} value={cost.note} />
            <DemandRecurringCostBlockBody>
              <DemandRecurringCostBlockInfo
                values={[
                  {
                    label: 'サプライヤー',
                    node: cost.supplier?.name || '-',
                  },
                  {
                    label: 'カテゴリー',
                    node: '費用',
                  },
                  {
                    label: '最終発注日',
                    node: dateFormatWithoutDayOfWeek(cost.orderRecurringCosts?.[0]?.updatedAt),
                  },
                  {
                    label: '社内メモ',
                    node: cost.internalMemos?.[0]?.body || '-',
                  },
                ]}
              />

              <DemandRecurringCostTable
                values={(cost.prices || []).map((price) => ({
                  id: price.id,
                  quantity: price.quantity,
                  unitPrice: price.unitPrice,
                  unitSellingPrice: price.unitSellingPrice,
                }))}
              />
            </DemandRecurringCostBlockBody>
            <DemandRecurringCostBlockFooter
              editPath={paths.demandRecurringCosts._id(cost.id).edit.url()}
              onDelete={() => {
                console.log('delete');
              }}
              disabledDelete={(cost.orderRecurringCosts || []).length > 0}
            />
          </DemandRecurringCostBlock>
        );
      })}
    </>
  );
};
