import { SalesOrderNodeType } from 'features/order/type';
import { OrderPreviewFragment$data } from 'gql/__generated__/OrderPreviewFragment.graphql';
import { concatFullName } from 'utils/label';
import { ZodOrderFormData } from '../zod';

type OrderDetailType = NonNullable<OrderPreviewFragment$data['details']>;

export const defaultDetailMessage = (
  companyInfo: { name: string; fullAddress: string },
  pic: { lastName: string; phoneNumber: string | null },
) => `荷主：
${companyInfo.fullAddress}
${companyInfo.name}
${pic.lastName} TEL: ${pic.phoneNumber || ''}

その他：
新規発注時はshizai宛に量産見本を3枚お送りください。
  ※リピート発注時は不要です
出来高生産など、発注数量に対して増産減産がある場合は必ずshizai担当者に事前連絡ください。
`;

export const convertItemFormat = (detail: {
  branchNumber: number;
  item: NonNullable<NonNullable<OrderDetailType[number]>['item']>;
}) => {
  return {
    id: detail.item.id,
    branchNumber: detail.branchNumber,
    name: detail.item.name,
    unitPrice: detail.item.unitPrice,
    quantity: detail.item.quantity,
    tax: {
      rate: detail.item.taxCategory.rate,
    },
  };
};

export const convertSalesOrderToFormStateFormat = ({
  salesOrder,
  companyInfo,
  currentUser,
}: {
  salesOrder?: SalesOrderNodeType | null;
  companyInfo: { name: string; fullAddress: string };
  currentUser: { lastName: string; phoneNumber: string | null; firstName: string; userID: string };
}): ZodOrderFormData | null => {
  if (!salesOrder) return null;

  const details = (salesOrder.details.edges || [])
    .map((edge) => edge?.node)
    .filter((v): v is NonNullable<typeof v> => v != null);

  const internalAssignees =
    salesOrder.internalAssignees?.map((assignee) => ({
      value: assignee.user.id,
      label: concatFullName({
        lastName: assignee.user?.profile?.[0].lastName || '',
        firstName: assignee.user?.profile?.[0].firstName || '',
      }),
    })) || [];

  return {
    company: {
      id: salesOrder.demand.id,
      name: salesOrder.demand.name,
    },
    supplier: {
      id: salesOrder.supplier.id,
      name: salesOrder.supplier.name,
    },
    title: '',
    detailMessage: defaultDetailMessage(companyInfo, currentUser),
    deliveryInfo: {},
    salesOrderId: salesOrder.id,
    details: [
      ...details
        .map((detail) => {
          const item = detail.item;
          if (!item) return null;

          return {
            name: item.name,
            unitPrice: item.unitPrice,
            quantity: item.quantity,
            tax: {
              id: item.taxCategory.id,
              rate: item.taxCategory.rate,
            },
            salesOrderDetailId: detail.id,
          };
        })
        .filter((v): v is NonNullable<typeof v> => v != null),
    ],
    internalAssignees,
  };
};

export const detailNumber = (transactionId: number, branchNumber: number): string => {
  // transactionId-branchNumberの形式, branchNumberは2桁で0埋めする
  return `${transactionId}-${branchNumber.toString().padStart(2, '0')}`;
};
